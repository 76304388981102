.spinner-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(255 255 255 / 32%);
  z-index: 9999;
  .spinner {
    border-radius: 50%;
    border-top: 5px solid #283891;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 0.8s linear infinite; /* Safari */
    animation: spin 0.8s linear infinite;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
